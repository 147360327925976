.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.shadow-custom {
  box-shadow: 2px 4px 10px 2px #332ba7;
}
.App-link {
  color: #61dafb;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 5px #ff0000, 0 0 10px #ff0000, 0 0 20px #ff0000,
      0 0 30px #ff0000, 0 0 40px #ff0000, 0 0 50px #ff0000, 0 0 60px #ff0000,
      0 0 70px #ff0000;
  }
  50% {
    text-shadow: 0 0 10px #ff0000, 0 0 20px #ff0000, 0 0 30px #ff0000,
      0 0 40px #ff0000, 0 0 50px #ff0000, 0 0 60px #ff0000, 0 0 70px #ff0000,
      0 0 80px #ff0000;
  }
  100% {
    text-shadow: 0 0 5px #ff0000, 0 0 10px #ff0000, 0 0 20px #ff0000,
      0 0 30px #ff0000, 0 0 40px #ff0000, 0 0 50px #ff0000, 0 0 60px #ff0000,
      0 0 70px #ff0000;
  }
}

.glow {
  font-size: 2em;
  color: #ff0000;
  text-align: center;
  animation: glow 1.5s infinite alternate;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Gotham";
  src: url(../public/Gotham-Font/gothamrnd_bold.otf);
}

.gotham {
  font-family: "Gotham";
}

@font-face {
  font-family: "Bigdigis";
  src: url(../public/Digib-Font//digib.TTF);
}

.bigdigis {
  font-family: "Bigdigis";
}
