@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.prueba {
  /* background-image: url("./pattern.png"); */
  background-size: cover;
  background-repeat: repeat-x;
}
.shadow-custom {
  box-shadow: 2px 4px 10px 2px #332ba7;
}

.bg-patron {
  background-image: url("./patron_3.png");
  background-size: contain;
}
